import React, { useState, useEffect } from "react";
import SEO from "../components/SEO";
import SubCatslider from "../components/Quiz/subcat/SubCatslider";
import Chapter from "../components/Quiz/subcat/Chapter";
import UnlockLevel from "../components/Quiz/subcat/UnlockLevel"; 
import { toast } from "react-toastify";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { withTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { scrollhandler } from "../utils";
import excla from "../assets/images/exclamation.png";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { categoriesApi, categoriesApiClass, levelDataApi,MindMapDataApi, subcategoriesApi,VideoDataApi, subcategoriesApiByClass } from "../store/actions/campaign"; 
import { selectCurrcategoriesApientLanguage } from "../store/reducers/languageSlice";
import { useLocation } from 'react-router-dom';
import VideoTut from "../components/Quiz/subcat/VideoTut"; 

const Video = () => {  
    const location = useLocation();
    const index = location;
    console.log(index.state);
    const [category, setCategory] = useState({ all: "", selected: "" });
    const [subCategory, setsubCategory] = useState({ all: "", selected: "" });
    const [level, setLevel] = useState([]);
    const [loading, setLoading] = useState(true);
    const [subloading, setSubLoading] = useState(true);
    const [levelloading, setLevelLoading] = useState(true);
    // const selectcurrentLanguage = useSelector(selectCurrentLanguage);
    //var selectcurrentLanguage = 0;
    const [selectcurrentLanguage, setselectcurrentLanguage] = useState(null);
    useEffect(() => {
        if (index.state && index.state.index) {
            setselectcurrentLanguage(index.state.index);
        }
    }, [index.state]) 

    const getAllData = () => {
        setCategory([]);
        setsubCategory([]);
        setLevel([]);

        // categories api  categoriesApi
        // level data api 
        if(index.state && index.state.data){
            console.log("==================================================>");
            console.log(index.state);

            //const lan = index.state.data.class_id;
            const lan = index.state.index.class;
            //const cat = index.state.data.category.id;
            const cat = index.state.index.subject; 
            //const sub_cat = index.state.data.subcategory.id;
            const sub_cat = index.state.data;


            //VideoDataApi(selectcurrentLanguage,categories[0].id, subcategories[0].id, (response) => {
                VideoDataApi(lan,cat,sub_cat, (response) => {
                let level = response.data;
                console.log(level);
                setLevel(level);
                setLevelLoading(false);
            },
                (error) => {
                    console.log(error);
                }
            );

        }
        
    };

 

 


    useEffect(() => {
        getAllData();
    }, [index]); 

    return (
        <React.Fragment>
            <SEO title={t("Video Tutorials")} />
            <Breadcrumb title={t("Video Tutorials")} content={t("Home")} contentTwo={t("Video Tutorials")} />
            <div className="quizplay mb-5">
                <div className="container">
                    <div className="row morphisam mb-5">
                        

                        {/* sub category middle sec */}
                        {/* <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-12 col-12"> */}
                            <div className="right__bottom cat__Box mt-4">
                                <span className="left-line"></span>
                                <h6 className="quizplay__title text-uppercase font-weight-bold" style={{textAlign:"center" ,margin:"20px",fontSize:"X-large"}}>{t("Video Tutorials")}</h6>
                                <span className="right-line"></span>
                            </div>

                            {/* levels sec */}
                            <div className="row custom_row" style={{marginLeft:"0px"}}>
                                <VideoTut level={level} levelLoading={levelloading} />
                                {/* <UnlockLevelClass></UnlockLevelClass> */}
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(Video);

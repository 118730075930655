import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import './page.css';
import { getTrackerDataApi, subcategoriesApiByClass } from "../store/actions/campaign";
import { useLocation } from 'react-router-dom';
import '../style.css'
import { func } from "prop-types";

export default function ChapterNew(props) {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const location = useLocation();
    const index = location;

    console.log(index);
    // alert("test");  


    function class_redirect(idx) {
        // navigate('/exercise', { state: { index: idx } });
        //navigate('/subject  ', { state: { index: idx,board:index.state.index } });
        navigate('/select-subject  ', { state: { class: idx, board: index.state.index } });
    }
    // console.log(index.state.index);

    useEffect(() => {
        subcategoriesApiByClass(index.state.class, index.state.subject, "", (response) => {
            let subcategories = response.data;
            if (!response.error && subcategories) {
                // setsubCategory({
                //     all: subcategories,
                //     selected: subcategories[0],
                // });
                console.log(subcategories);

                setData(subcategories);
                //setSubLoading(false);

                // level data api 

            }
        },
            (error) => {
                console.log(error);
            }
        );
        // if (index.state && index.state.index) {
        //     var myHeaders = new Headers();
        //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        //     myHeaders.append("Cookie", "ci_session=m3jb7o7i9u9t94jaomomc3gononglodj");

        //     var urlencoded = new URLSearchParams();
        //     urlencoded.append("access_key", "8525");
        //     urlencoded.append("board_id", index.state.index);

        //     var requestOptions = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: urlencoded,
        //         redirect: 'follow'
        //     };

        //     fetch("https://shresthaedutech.netsofters.net/admin/api/get_class_from_board", requestOptions)
        //         .then(response => response.json())
        //         .then(result => setData(result.data))
        //         .catch(error => console.log('error', error));

        // }
    }, [index.state])
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: '3px',
        paddingLeft: '20px', // Adjust the left padding as needed
        paddingRight: '20px', // Adjust the right padding as needed
        marginBottom: '10px',
        backgroundColor: 'white',
        borderBottom: '1px solid #ccc',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    };

    const headerTextStyle = {
        margin: '0',
        marginRight: 'auto', // Use margin-right to push the header to the left
        fontWeight: 'normal',
    };

    const navLinkStyle = {
        padding: '2px',
        color: '#333',
        textDecoration: 'none',
    };

    const btnStyle = {
        padding: '5px 10px',
        borderColor: '#007bff',
        color: '#007bff',
        textDecoration: 'none',
    };

    function redirect(idx, chapter, maxlevel) {
        if (idx === 0) {

            navigate('/exercise', { state: { index: index.state, data: chapter, subcat: maxlevel } });
        }
        ///video
        if (idx === 1) {
            navigate('/video-section', { state: { index: index.state, data: chapter } });
        }
        //mind-map
        if (idx === 2) {
            navigate('/mind-map-section', { state: { index: index.state, data: chapter } });
        }
        //work-sheet
        if (idx === 3) {
            navigate('/work-sheet', { state: { index: index.state, data: chapter } });
        }//ncert
        if (idx === 4) {
            navigate('/ncert', { state: { index: index.state, data: chapter } });
        }
        //concept
        if (idx === 5) {
            navigate('/concept', { state: { index: index.state, data: chapter } });
        }
    }

    return (

        <div>
            {/* <Breadcrumb title={"chapter"} content={"Home"} contentTwo={"chapter"} /> */}

            <div className="container" style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>

                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <div className="career-search mb-60">



                            <div className="Quizzone" style={{ margin: "50px 0px" }}>
                                <div className="container" style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>


                                    {/* <div style={{ maxWidth: "650px", display: "flex", flexDirection: "column", justifyContent: "center", flexWrap: "wrap", alignItems: "center" }}> */}
                                    <div style={{ width: "100%", padding: "10px", marginBottom: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <div>
                                            <img src={index.state.image} alt="icon" style={{ width: "120px", height: "120px", borderRadius: "10px" }} />
                                        </div>
                                        <div style={{ paddingLeft: "25px" }}>
                                            <h1 style={{ margin: "0px" }}>{index.state.name}</h1>
                                        </div>

                                        {/* <hr></hr> */}
                                    </div>
                                    <div
                                        style={{
                                            borderTop: "1px solid #b2a9a9",
                                            width: "100%",
                                            margin: "10px 0",
                                        }}
                                    />
                                </div>
                            </div>




                            <div className="filter-result" style={{ display: "flex", alignItems: "center", flexDirection: "column", width: "100%" }}>
                                        
                                {data && data.map((quiz) => (
                                    <>
                                        {/* <div className="hoverColour" key={quiz.id} style={{ display: "flex", flexDirection: "row", margin: "10px", alignItems: "center", padding: "10px", borderRadius: "10px", alignItems: "center", border: "1px solid #b2a9a9" }}> */}



                                        <div className="job-box d-md-flex align-items-center justify-content-between mb-30" style={{ maxWidth: "770px", minWidth: "370px", padding: "10px 50px", width: "100%" }}>
                                            <div className="job-left my-4 d-md-flex align-items-center flex-wrap" style={{ width: "100%" }}>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <img src={quiz.image} alt="icon" style={{ width: "70px", height: "70px", borderRadius: "50%" }} />
                                                </div>
                                                <div className="job-content">
                                                    <div>

                                                        <h5 className="text-center text-md-left">{quiz.subcategory_name}</h5>
                                                    </div>

                                                    <ul className="d-md-flex flex-wrap text-capitalize ff-open-sans" style={{ justifyContent: "space-around" }}>
                                                        <li className="mr-md-4" style={{ marginRight: "25px", cursor: "pointer" }} onClick={() => redirect(1, quiz.id)}>
                                                            <i className="zmdi zmdi-pin mr-2" /> video
                                                        </li>
                                                        <li className="mr-md-4" style={{ marginRight: "25px", cursor: "pointer" }} onClick={() => redirect(0, quiz.id, quiz)}>
                                                            <i className="zmdi zmdi-money mr-2" /> Exercise
                                                        </li>
                                                        <li className="mr-md-4" style={{ marginRight: "25px", cursor: "pointer" }} onClick={() => redirect(5, quiz.id)}>
                                                            <i className="zmdi zmdi-time mr-2" /> Concepts
                                                        </li>

                                                        <li className="mr-md-4" style={{ marginRight: "25px", cursor: "pointer" }} onClick={() => redirect(2, quiz.id)}>
                                                            <i className="zmdi zmdi-time mr-2" /> Mind Map
                                                        </li>
                                                        <li className="mr-md-4" style={{ marginRight: "25px", cursor: "pointer" }} onClick={() => redirect(3, quiz.id)}>
                                                            <i className="zmdi zmdi-time mr-2" /> Worksheet
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>

                                        {/* </div> */}
                                    </>

                                ))}


                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </div>
    )
}

{/* <div>

    {data && data.map((quiz) => (
        <>
            <div className="hoverColour" key={quiz.id} style={{ display: "flex", flexDirection: "row", margin: "10px", alignItems: "center", padding: "10px", borderRadius: "10px", alignItems: "center", border: "1px solid #b2a9a9" }}>
                <div>
                    <img src={quiz.image} alt="icon" style={{ width: "50px", height: "50px", borderRadius: "10px" }} />
                </div>
                <div style={{ padding: "10px", marginLeft: "15px" }}>
                    <h4 style={{ margin: "0px" }}>{quiz.subcategory_name}</h4>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => redirect(1, quiz.id)}>video |</div>
                        <div style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => redirect(0, quiz.id, quiz)}>Exercise |</div>
                        <div style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => redirect(5, quiz.id)}>Concepts |</div>
                        <div style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => redirect(2, quiz.id)}>Mind Map |</div>
                        <div style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => redirect(3, quiz.id)}>Worksheet </div>

                        {index.state.board == 5 &&
                            <div style={{ marginRight: "10px", cursor: "pointer" }} onClick={() => redirect(4, quiz.id)}>| Ncert</div>
                        }
                    </div>

                </div>

            </div>
            <div>

            </div>
        </>

    ))}
</div> */}

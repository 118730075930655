import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { FaLock, FaUnlock } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { t } from "i18next";
import { Loadtempdata } from '../../../store/reducers/tempDataSlice';
import Accordion from 'react-bootstrap/Accordion';

const ConceptShow = (data) => {
    console.log(data);
    // const handleLockClick = () => {
    //     toast.error(t("This Level is Locked. Play previous levels to unlock"));
    // }; 





    const handleLoadData = (alldata) => {
        Loadtempdata(alldata);
    }


    // const handleChangeCategory = (pdf) => {
    //     // alert(pdf);
    //     window.open(pdf, '_blank');
    // }

    return (
        <Fragment>
            {data.levelLoading ? (
                <div className="text-center">
                    <Skeleton count={5} />
                </div>
            ) :


                (
                    <>
                        {data.level.length > 0 ?


                            (
                                <div>
                                    {console.log(data)}
                                    {data.level.map((data, key) => {

                                        return (
                                            <Accordion defaultActiveKey="0" style={{ borderRadius: "10px", margin: "10px" }}>
                                                <Accordion.Item eventKey={key}>
                                                    <Accordion.Header>{data.title}</Accordion.Header>
                                                    <Accordion.Body style={{ color: "black" }}>
                                                        <style>
                                                            {`
          a {
            color: blue;
          }
        `}
                                                        </style>

                                                        {/* {data.description} */}
                                                        {/* dangerouslySetInnerHTML={{ __html: data.description }} */}
                                                        <div dangerouslySetInnerHTML={{ __html: data.description }} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                {/* <Accordion.Item eventKey="1">
                                                <Accordion.Header>Accordion Item #2</Accordion.Header>
                                                <Accordion.Body>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.
                                                </Accordion.Body>
                                            </Accordion.Item> */}
                                            </Accordion>
                                        );


                                    })}
                                </div>
                            ) :







                            (
                                <div className="text-center mt-4 commonerror">
                                    <img src={process.env.PUBLIC_URL + "/images/error/error.svg"} title="wrteam" className="error_img" />
                                    <p>{t("No Levels Data Found")}</p>
                                </div>

                            )}
                    </>
                )}


        </Fragment>
    );
};
export default ConceptShow;

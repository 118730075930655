import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { FaLock, FaUnlock } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { t } from "i18next";
import { Loadtempdata } from '../../../store/reducers/tempDataSlice';

const VideoTut = (data) => { 
    console.log(data.level.length);
    const handleLockClick = () => {
        toast.error(t("This Level is Locked. Play previous levels to unlock"));
    };
 
    const handleLoadData = (alldata) => {
        Loadtempdata(alldata);
    }

    const handleChangeCategory = (pdf) => {
        //  alert(pdf);
        // console.log()
        // window.open(pdf, '_blank');
    }

    return (
        <Fragment>
            {data.levelLoading ? (
                <div className="text-center">
                    <Skeleton count={5} />
                </div>
            ) :


                (
                    <>
                        {data.level.length > 0 ?


                            ( 
                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                                    {console.log(data.level)}
                                    {data.level.map((data, key) => {
                                        return (
                                            <div className="w-100 button" style={{ margin: "10px", padding: "10px", borderRadius: "10px", maxWidth: "400px",backgroundColor:"rgb(245,245,248)" }}>
                                                {/* <li className="d-flex" key={key} onClick={(e) => handleChangeCategory(data.image)} style={{ justifyContent: "center", alignItems: "center" }}>
                                                    <div><img src={data.image} width={"300px"} style={{borderRadius:"10px"}}></img></div>
                                                </li> */}
                                                <div>
                                                    {console.log(data.link)}
                                                    <div style={{textAlign:"center" , textTransform:"capitalize"}}><h3>{data.title}</h3></div>
                                                    <hr></hr>
                                                    <div style={{display:"flex", justifyContent:"center", cursor: "pointer", margin:"15px"}}>
                                                        <iframe
                                                        width="325" // Adjust dimensions as needed
                                                        height="300"
                                                        src={data.link} // Replace VIDEO_ID with the actual YouTube video ID
                                                        // title="YouTube Video"
                                                        frameborder="0"
                                                        allowfullscreen
                                                    ></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) :







                            (
                                <div className="text-center mt-4 commonerror">
                                    <img src={process.env.PUBLIC_URL + "/images/error/error.svg"} title="wrteam" className="error_img" />
                                    <p>{t("No Levels Data Found")}</p>
                                </div>

                            )}
                    </>
                )}


        </Fragment>
    );
};
export default VideoTut;

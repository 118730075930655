import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { useLocation } from 'react-router-dom';
import { categoriesApiClass } from "../store/actions/campaign";
import './style.css'


export default function SubjectNew() {
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location);
    const [board, setBoard] = useState(null);

    const [subject, setSubject] = useState(null);


    useEffect(() => {
        if (location.state && location.state.class) {
            categoriesApiClass(1, location.state.class,
                (response) => {
                    let categories = response.data;
                    setSubject(categories)
                    console.log(categories);
                },
                (error) => {
                    console.log(error);
                    toast.error(t("No Data found"));
                }
            );
        }

    }, [location.state])


    // useEffect(() => {

    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    //     myHeaders.append("Cookie", "ci_session=1l798qc7qvaqac7k8p70768qs14ma3qp");

    //     var urlencoded = new URLSearchParams();
    //     urlencoded.append("access_key", "8525");

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: urlencoded,
    //         redirect: 'follow'
    //     };

    //     fetch("https://shresthaedutech.netsofters.net/admin/api/get_board", requestOptions)
    //         .then(response => response.json())
    //         .then(result => setBoard(result.data))
    //         .catch(error => console.log('error', error));

    // }, [])

    function class_redirect(idx, name, image) {
        navigate('/select-chapter', { state: { subject: idx, name: name, image: image, class: location.state.class, board: location.state.board } });
    }
    return (
        <div>

            <style>
                {`
          .Quizzone .inner__Quizzone .card::after {
            position: absolute;
            content: "";
            background-color: #0f0a0c;
          }
        `}
            </style>
            {/* <Breadcrumb title={"Popular Boards & Exams"} content={"Home"} contentTwo={"Board"} /> */}
            {/* <h1>Popular Boards & Exams</h1> */}
            <div className="Quizzone">
                <div className="container" style={{ marginBottom: "25px", display: "flex", justifyContent: "center" }}>

                    <h1 style={{ margin: "10px" }}><span style={{ color: "blue" }}>Learn</span> with Video classes</h1>
                </div>
                <div className="container" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {subject && subject.map((quiz) => (
                        <div key={quiz.id} onClick={() => class_redirect(quiz.id, quiz.category_name, quiz.image)} style={{ display: "flex", flexDirection: "column", margin: "20px 30px", alignItems: "center", padding: "5px", borderRadius: "10px", cursor: "pointer", alignItems: "center" }}>
                            <div>
                                <img src={quiz.image} alt="icon" style={{ width: "100px", height: "100px", borderRadius: "20px" }} />
                            </div>
                            <div style={{ padding: "10px" }}>
                                <h5 style={{ margin: "0px" }}>{quiz.category_name}</h5>
                            </div>

                        </div>

                    ))}

                </div>

            </div>

            




        </div>
    )
}

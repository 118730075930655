import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { useLocation } from 'react-router-dom';

export default function SelectSection(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const index = location;
   console.log(props);
    console.log(index.state.board) 

    function class_redirect(idx) { 
        // navigate('/exercise', { state: { index: idx } });
        if (idx === 0) {
            navigate('/exercise', { state: { index: index.state.index ,data :props} });
        }
        ///video
        if (idx === 1) { 
            navigate('/video-section', { state: { index: index.state.index ,data :props} });
        }
        //mind-map
        if (idx === 2) {
            navigate('/mind-map-section', { state: { index: index.state.index ,data :props} });
        }
        //work-sheet
        if (idx === 3) {
            navigate('/work-sheet', { state: { index: index.state.index ,data :props} });
        }//ncert
        if (idx === 4) {
            navigate('/ncert', { state: { index: index.state.index ,data :props} });
        }
        if (idx === 5) {
            navigate('/concept', { state: { index: index.state.index ,data :props} });
        } 
    }
    // const [board, setBoard] = useState(null);

    // useEffect(() => {

    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    //     myHeaders.append("Cookie", "ci_session=1l798qc7qvaqac7k8p70768qs14ma3qp");

    //     var urlencoded = new URLSearchParams();
    //     urlencoded.append("access_key", "8525");

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: urlencoded,
    //         redirect: 'follow'
    //     };

    //     fetch("https://shresthaedutech.netsofters.net/api/get_board", requestOptions)
    //         .then(response => response.json())
    //         .then(result => setBoard(result.data))
    //         .catch(error => console.log('error', error));

    // }, [])

    const [board, setData] = useState([
        {
            id: 0,
            board_name: "Exercise (MCQ)",
        },
        {
            id: 1,
            board_name: "Video Tutorials",
        },
        {
            id: 2,
            board_name: " Mind Map",
        },
        {
            id: 3,
            board_name: "Worksheet",
        },
        {
            id: 5,
            board_name: "Concept",
        },
        {
            id: 4,
            board_name: "Ncert",
        },


    ]);

    // function class_redirect(idx) {
    //     navigate('/class', { state: { index: idx } });
    // } 
    return (
        <div>
            <style>
                {`
          .Quizzone .inner__Quizzone .card::after {
            position: absolute;
            content: "";
            background-color: #0f0a0c;
          }
        `}
            </style>
           {/* // <Breadcrumb title={"Popular Boards & Exams"} content={"Home"} contentTwo={"Board"} /> */}
            {/* <h1>Popular Boards & Exams</h1> */}
            <div className="Quizzone" style={{margin:"0px"}}>
                <div className="container" style={{padding:"0px"}}>
                    <ul className="row justify-content-start">
                        {board && board.map((quiz) => (
                            <>
                                {index.state.board == 5 ?
                                    <li className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6 small__div" key={quiz.id} onClick={() => class_redirect(quiz.id)}>
                                        <div className="inner__Quizzone">

                                            <div className="card">

                                                <div >
                                                    <h5 className="inner__title">{quiz.board_name}</h5>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    :
                                    <>
                                        {
                                            quiz.id != 4 && <li className="col-xl-2 col-lg-3 col-md-3 col-sm-6 col-6 small__div" key={quiz.id} onClick={() => class_redirect(quiz.id)}>
                                                <div className="inner__Quizzone">

                                                    <div className="card">

                                                        <div >
                                                            <h5 className="inner__title">{quiz.board_name}</h5>
                                                        </div>
                                                    </div>

                                                </div>
                                            </li>
                                        }
                                    </>
                                }
                            </>

                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

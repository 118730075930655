import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { FaLock, FaUnlock } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { t } from "i18next";
import { Loadtempdata } from '../../../store/reducers/tempDataSlice';

const WorksheetDownload = (data) => {
    console.log(data.level.length);
    const handleLockClick = () => {
        toast.error(t("This Level is Locked. Play previous levels to unlock"));
    };

    const handleLoadData = (alldata) => {
        Loadtempdata(alldata);
    } 

    const handleChangeCategory = (pdf) => {
        // alert(pdf);
        window.open(pdf, '_blank');
    }

    return (
        <Fragment>
            {data.levelLoading ? (
                <div className="text-center">
                    <Skeleton count={5} />
                </div>
            ) :


                (
                    <>
                        {data.level.length > 0 ?


                            (
                                <div style={{ display: "flex" ,flexWrap:"wrap",justifyContent:"center"}}>
                                    {console.log(data.level)}
                                    {data.level.map((data, key) => {
                                        return (
                                            <div className="w-100 button active-one" style={{ margin: "10px", padding: "10px", borderRadius: "10px", cursor: "pointer", maxWidth:"225px" }}>
                                                <li className="d-flex" key={key} onClick={(e) => handleChangeCategory(data.pdf)} style={{ justifyContent: "center", alignItems: "center" }}>
                                                    {/* <div  > */}
                                                    {/* <span className="Box__icon">
                                                                            <img src={data.image ? data.image : `${excla}`} alt="image" />
                                                                        </span> */}
                                                    <div className="Box__text" >{(data.title)}</div>
                                                    {/* </div> */}
                                                </li>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) :







                            (
                                <div className="text-center mt-4 commonerror">
                                    <img src={process.env.PUBLIC_URL + "/images/error/error.svg"} title="wrteam" className="error_img" />
                                    <p>{t("No Levels Data Found")}</p>
                                </div>

                            )}
                    </>
                )}


        </Fragment>
    );
};
export default WorksheetDownload;
